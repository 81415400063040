import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// components
import DownloadButton from '../components/DownloadButton';
import SectionHeading from '../components/SectionHeading';
import AppFeatureSingle from '../components/AppFeatureSingle';
import Button from '../components/Button';
import PricingSlider from '../components/PricingSlider';
import TestimonialSlider from '../components/TestimonialSlider';
import ScreenSlider from '../components/ScreenSlider';
import ContactForm1 from '../components/ContactForm1';
import ContactForm2 from '../components/ContactForm2';
import BlogSingle from '../components/BlogSingle';
import BackgroundAnimation from '../components/BackgroundAnimation';
import VideoLightBox from '../components/VideoLightBox';

// images
import hero_hal from '../../assets/images/hero-hal.png';
import artwork from '../../assets/images/Artwork.png';
import video_img_bittensor from '../../assets/images/video-img-bittensor.png';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';
import background_image_2 from '../../assets/images/patterns/pattern-2.jpg';
import perfect_match from '../../assets/images/perfect-match.png';
import artwork_left from '../../assets/images/Artwork-left.png';
import artwork_right from '../../assets/images/Artwork-right.png';

// data
import blog from '../data/blog';
import features from '../data/features';
import faq from '../data/faq';

const Index = () => {
    const { state } = useLocation();
    const { targetId, block } = state || "";

    useEffect(() => {
        setTimeout(() => {
            document.getElementById(targetId)?.scrollIntoView({ behavior: "smooth", block })
        }, (250));
      }, [targetId, block]);

    const hidePricingSection = true;
    const hideTestimonialSection = true;
    const hideScreensSection = true;
    const hideBlogSection = true;
    const hideCtaSection = true;
    const hideContactSection = true;

    return (
        <>
            {/* hero - start */}
            <div className="hero hero-1">
                <div className="hero-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                                <div className="hero-content">
                                    <h1 className="c-dark">Meet Hal.</h1>
                                    <p className="large c-grey">Introducing Hal, your personal AI assistant <b>powered by Bittensor.</b> With Hal, you can now access
                                    decentralized and uncensorable AI directly from your favorite social networks and messenger apps. <br/>Whether you need a quick
                                    chat or a deep conversation, Hal is here to make your digital experience smarter and more engaging than ever before.</p>
                                    <DownloadButton/>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="hero-image">
                                    <img className="drop-shadow" src={hero_hal} alt="hero" />
                                    <div className="hero-absolute-image">
                                        <img src={artwork} alt="artwork" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* hero - end */}

            {/* app feature - start */}
            <div className="app-feature app-feature-1">
                <div className="app-feature-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    id="platforms"
                                    heading='supported platforms'
                                    subHeading='Connect with Hal Anywhere.'
                                    icon='las la-cog'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row gx-5 gy-5">
                            { features.map((element, key) => {
                                return (
                                    <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1" key={key}>
                                        <AppFeatureSingle
                                            icon={element.icon}
                                            heading={element.heading}
                                            excerpt={element.excerpt}
                                            containerClass='app-feature-single-2'
                                            link={element.to}
                                        />
                                    </div>
                                );
                            }) }
                        </div>
                    </div>
                </div>
            </div>
            {/* app feature - end */}

            {/* video - start */}
            <div className="video-section" id="bittensor">
                <div className="video-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 offset-lg-1 order-lg-1 col-10 offset-1 order-2">
                                <div className="video-section-content">
                                    <div className="section-heading section-heading-1 center-responsive c-white">
                                        <div className="sub-heading upper ls-1">
                                            <i className="las la-video"></i>
                                            <h5>Powered by Bittensor</h5>
                                        </div>
                                        <div className="main-heading">
                                            <h1>Bittensor is an open-source protocol that powers a scalable, decentralized neural network.</h1>
                                        </div>
                                    </div>
                                    <Button
                                        to="https://docs.bittensor.com"
                                        content="Learn More"
                                        type="button-1"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 order-lg-2 order-1">
                                <div className="video-section-video">
                                    <figure>
                                        <img className="drop-shadow-1" src={video_img_bittensor} width='471' height='472' alt="drop-shadow" />

                                        <div className="play">
                                            <VideoLightBox URL='https://www.youtube.com/watch?v=qQcYTzCBCBE' />
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* video - end */}

            {/* about hal section - start */}
            <div className="feature-section feature-section-1 feature-section-spacing-2">
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                                <div className="feature-section-image">
                                    {/* <img src={feature_image_2} className="image" alt="feature-fore" /> */}
                                    <img src={perfect_match} className="phone match" alt="phone" />
                                    <div className="background-pattern background-pattern-radius-reverse">
                                        <BackgroundAnimation/>
                                        <div className="background-pattern-gradient"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="feature-section-content">
                                    <SectionHeading
                                        id="about"
                                        icon='las la-address-card'
                                        heading='about hal'
                                        subHeading='Bittensor and Hal: A Perfect Match.'
                                    />
                                    <p className="c-grey"><b>Bittensor and Hal: A Perfect Match Inspired by Hal Finney</b></p>
                                    <p className="c-grey">Bittensor's mainnet, aptly named Finney in honor of the renowned computer scientist Hal Finney, aligns perfectly with our AI-powered chatbot, Hal. Just as Hal Finney made significant contributions to the world of cryptocurrencies, Hal, powered by Bittensor, brings decentralized and uncensorable AI directly to your favorite social networks and messenger apps. Experience the synergy of Bittensor and Hal, where cutting-edge technology meets a legendary figure in the blockchain space.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature section - end */}

            {/* pricing section - start */}
            { !hidePricingSection && (
            <div className="pricing-section">
                <div className="pricing-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-tags'
                                    heading='our app rates'
                                    subHeading='Pricing plans for you.'
                                    additionalClasses='center c-white'
                                    mainHeadingClass='c-white'
                                    subHeadingClass='c-white'
                                />
                            </div>
                        </div>
                        <PricingSlider/>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* pricing section - end */}

            {/* testimonial section - start */}
            { !hideTestimonialSection && (
            <div className="testimonial-section">
                <div className="testimonial-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-comments'
                                    heading='feedbacks'
                                    subHeading='What people are talking about.'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <TestimonialSlider/>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* testimonial section - end */}

            {/* faq section - start */}
            <div className="faq-section">
                <div className="faq-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-10 offset-xxl-1 col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    id='faq'
                                    icon='las la-file-alt'
                                    heading='learn'
                                    subHeading='Some frequently asked questions'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-9 col-md-8 col-10">
                                <div className="faq-wrapper">
                                    <div className="faq" id="faq-accordion">
                                        {
                                            faq.map((element, key) => {
                                                return (
                                                    <div className="accordion-item" key={key}>
                                                        <div className="accordion-header" id={`faq-${key}`}>
                                                            <button
                                                            className={`accordion-button ${key !== 1 ? 'collapsed' : ''}`}
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#faq-collapse-${key}`}
                                                            aria-expanded={key === 1 ? 'true': 'false'}
                                                            aria-controls={`faq-collapse-${key}`}>
                                                                <span>{element.question}</span>
                                                            </button>
                                                        </div>
                                                        <div
                                                        id={`faq-collapse-${key}`}
                                                        className={`accordion-collapse collapse ${key === 1 ? 'show' : ''}`}
                                                        aria-labelledby={`faq-${key}`}
                                                        data-bs-parent="#faq-accordion">
                                                            <div className="accordion-body">
                                                                <p>{element.answer}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* faq section - end */}

            {/* screen section - start */}
            { !hideScreensSection && (
            <div className="screen-section">
                <div className="screen-section-wrapper">
                    <ScreenSlider/>
                    {/* screen section bottom - start */}
                    <div className="screen-section-bottom">
                        <div className="screen-section-bottom-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-0 col-10 offset-1">
                                        <h1 className="c-white">Download from both Android and iOS</h1>
                                        <h4 className="c-white">
                                            <i className="fas fa-smile" style={{marginRight: '1rem'}}></i>
                                            3 Million users and counting!
                                        </h4>
                                    </div>
                                    <div className="col-lg-4 offset-lg-0 col-10 offset-1">
                                        <DownloadButton groupType='download-button-1-group' buttonType='download-button-1' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* screen section bottom - end */}
                </div>
                <div className="background-pattern background-pattern-2">
                    <BackgroundAnimation/>
                    <div className="background-pattern-gradient"></div>
                    <div className="background-pattern-bottom">
                        <div className="image" style={{backgroundImage: `url(${background_image_2})`}}></div>
                    </div>
                </div>
            </div>
            )}
            {/* screem section - end */}

            {/* blog section - start */}
            { !hideBlogSection && (
            <div className="blog-section">
                <div className="blog-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-bullhorn'
                                    heading='recent news'
                                    subHeading='Read latest news from our blog.'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                        <div className="row gx-5">
                            {
                                blog.slice(0, 3).map((element, key) => {
                                    return (
                                        <div key={key} className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                            <BlogSingle element={element} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* blog section - end */}

            {/* cta section - start */}
            { !hideCtaSection && (
            <div className="cta-section">
                <div className="cta-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-cloud-download-alt'
                                    heading='Download Hal'
                                    subHeading='Join Hal and get 20% off on your selected plan.'
                                    additionalClasses='center width-71'
                                />
                                <DownloadButton/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* cta section - end */}

            {/* contact form section - start */}
            { !hideContactSection && (
            <div className="contact-form-section contact-form-section-1">
                <div className="contact-form-section-wrapper">
                    <div className="container">
                        <div className="row gx-5 contact-form-section-row">
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* contact form - start */}
                                <ContactForm1/>
                                {/* contact form - end */}
                            </div>
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* newsletter form - start */}
                                <ContactForm2/>
                                {/* newsletter form - end */}
                            </div>
                        </div>
                    </div>
                    <div className="contact-form-section-pattern">
                        <div className="left" style={{backgroundImage: `url(${artwork_left})`}}></div>
                        <div className="right" style={{backgroundImage: `url(${artwork_right})`}}></div>
                    </div>
                </div>
            </div>
            )}
            {/* contact form section - end */}
        </>
    );
};

export default Index;
