import React from 'react';

const DownloadButton = ({ groupType, buttonType }) => {
    return (
        <div className={`download-button-group ${groupType}`}>
            <a href="#platforms" className={`download-button download-button-google ${buttonType}`}>
                <div className="download-button-inner">
                    <div className="download-button-icon c-purple">
                        <i className="fas fa-comments"></i>
                    </div>
                    <div className="download-button-content">
                        <h3 className="c-dark ls-2">Get started</h3>
                    </div>
                </div>
            </a>
            <a href="https://www.bittensor.com/" className={`download-button download-button-apple ${buttonType}`} target='_blank' rel='noreferrer'>
                <div className="download-button-inner">
                    <div className="download-button-icon c-pink">
                        <i className="fas fa-link"></i>
                    </div>
                    <div className="download-button-content">
                        <h3 className="c-dark ls-2">About Bittensor</h3>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default DownloadButton;
