import BackgroundAnimation from '../components/BackgroundAnimation';
import TabSection from '../components/TabSection';
import Button from '../components/Button';
import discord from '../../assets/images/discord-convo.png';
import addToServer from '../../assets/images/add-to-server.png';

const data = [{
  icon: "las la-broadcast-tower",
  title: "Live in beta",
  content: (
      <>
          <p className="paragraph-big">We're excited to announce that Hal is now available in beta on Discord! Join our beta testing phase and experience AI-powered conversations with Hal on Discord before the full release.</p>
          <div className="pattern-image">
              <div className="pattern-image-wrapper">
                  <img className="drop-shadow-1" src={discord}  alt="discord" />
                  <div className="background-pattern background-pattern-radius drop-shadow-1">
                      <BackgroundAnimation/>
                      <div className="background-pattern-gradient"></div>
                  </div>
              </div>
          </div>
          <h3 className="c-dark">How it works on Discord.</h3>
          <p>Choose one of the following options to interact with Hal on Discord:</p>
          <p><b>Option 1:</b></p>
          <p className="mt-0">Search for Hal on Discord, you'll only find Hal if you and Hal share a mutual server (username: <b>Hal#6630</b>)</p>
          <p className="mt-0">Send Hal a private message to initiate a conversation.</p>
          <p><b>Option 2:</b></p>
          <p className="mt-0">If you're in a server that has the Hal bot, you can use the following commands to interact with Hal:</p>
          <ul>
            <li><b>/helphal</b>: displays helpful information about Hal.</li>
            <li><b>/abouthal</b>: provides details about Hal.</li>
            <li><b>/chatwithhal [your question]</b>: ask Hal a question by using this command followed by your question.</li>
          </ul>
            <p>Please note that conversations initiated using the <b>/chatwithhal</b> command are public and visible to everyone in the channel where Hal is present.</p>
            <p>Experience the power of Hal on Discord and engage in AI-driven conversations with ease.</p>
      </>
  )
},
{
    icon: "lab la-discord",
    title: "Server owners",
    content: (
        <>
            <p className="paragraph-big">Discord server owners can enhance their community's experience by adding Hal to their server. Simply invite Hal and your users will have access to powerful AI-driven conversations right from within your server. Enable engaging discussions, provide assistance, and unlock the potential of decentralized AI with Hal.</p>
            <div className="pattern-image">
                <div className="pattern-image-wrapper">
                    <img className="drop-shadow-1" src={addToServer}  alt="add-to-server" />
                    <div className="background-pattern background-pattern-radius drop-shadow-1">
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                    </div>
                </div>
            </div>
            <h3 className="c-dark">How to add Hal to your server.</h3>
            <Button
                to='https://discord.com/api/oauth2/authorize?client_id=1106684841212264489&permissions=311385131072&scope=bot%20applications.commands'
                content='Add Hal to your server Now'
                type='button button-3'
                icon={<i className="lab la-discord"></i>}
            />

            <p><b>Or Alternatively</b></p>
            <p className="mt-0">Search for Hal on Discord, you'll only find Hal if you and Hal share a mutual server (username: <b>Hal#6630</b>) and press the <b>Add to Server</b> button on the profile page.</p>
        </>
    )
  },
  {
    icon: "las la-vial",
    title: "Hal's Playground",
    content: (
        <>
            <p className="paragraph-big">Join our Chat with Hal Discord server and embark on an interactive journey with Hal, the AI-powered chatbot. Experience decentralized AI firsthand and engage in dynamic conversations. Explore the possibilities, ask questions and provide feedback if you like.</p>
            <Button
                to='https://discord.gg/rUSevPzwWQ'
                content='Try Hal on Discord Now'
                type='button button-3'
                icon={<i className="lab la-discord"></i>}
            />
        </>
    )
  }]

const ComingSoon = () => {
    return <TabSection data={data} subHeading='How it Works' />
};

export default ComingSoon;
