import React from 'react';
import { useParams } from 'react-router-dom';

import ComingSoon from '../platforms/ComingSoon';
import Discord from '../platforms/Discord';

import PageHeader from '../components/PageHeader';

const platforms = {
    discord: 'Discord',
    twitter: 'Twitter',
    nostr: 'Nostr',
    whatsapp: 'WhatsApp',
    mastodon: 'Mastodon',
    telegram: 'Telegram'
}

const FeatureDetail1 = () => {
    const params = useParams();
    const platform = params.platform?.toLowerCase();

    // return to home page if platform is not valid
    if (!platform || !platforms[platform]) {
        console.log('invalid platform')
        window.location.href = '/';
    }

    const platformTitle = platforms[platform];

    const renderPlatform = () => {
        switch (platform) {
            case 'discord':
                return <Discord />
            default:
                return <ComingSoon platform={platformTitle} />
        }
    }


    return (
        <>
            {/* page header - start */}
            <PageHeader
                title={`Hal on ${platformTitle}`}
                pages={[
                    {
                        title: 'Home',
                        to: '/'
                    },
                    {
                        title: platformTitle,
                        to: `/platforms/${platform}`
                    }
                ]}
            />
            {/* page header - end */}

            {renderPlatform()}

            {/* instagram - start */}
            {/* <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                        <Button
                            to='/'
                            content='Follow us on Instagram'
                            type='button-premium'
                            icon={<i className="fab fa-instagram"></i>}
                        />
                    </div>
                </div>
            </div> */}
            {/* instagram - end */}
        </>
    );
};

export default FeatureDetail1;
