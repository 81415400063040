import React from 'react';

import { Link } from 'react-router-dom';

// images
import logo from '../../assets/images/logo-hal.png';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-row">
                                <div className="footer-detail">
                                    <div className="footer-logo">
                                        <Link to="#">
                                            <img src={logo} alt="footer-logo" />
                                        </Link>
                                        <p>Chat with Hal</p>
                                    </div>
                                    <p className="c-grey-1 mt-0">Chat with Hal, your personal AI assistant powered by Bittensor.</p>
                                    {/* <div className="links">
                                        <a className="link-underline" href="mailto:info@chatwithhal.ai">
                                            <span>info@chatwithhal.ai</span>
                                        </a>
                                        <a className="link-underline" href="tel:+1-234-567-890">
                                            <span>+1 234 567 890</span>
                                        </a>
                                    </div> */}
                                </div>
                                <div className="footer-list footer-social social-gradient">
                                    <h6>Follow</h6>
                                    <ul>
                                        <li className="twitter">
                                            <a href="https://www.twitter.com/chatwithhal" className="link-underline" target='_blank' rel='noreferrer'>
                                                <i className="fab fa-twitter"></i>
                                                <span>Twitter</span>
                                            </a>
                                        </li>
                                        <li className="facebook">
                                            <a href="https://discord.gg/rUSevPzwWQ" className="link-underline" target='_blank' rel='noreferrer'>
                                                <i className="fab fa-discord"></i>
                                                <span>Chat with Hal</span>
                                            </a>
                                        </li>
                                        <li className="discord">
                                            <a href="https://discord.gg/sfAqc6Su5c" className="link-underline" target='_blank' rel='noreferrer'>
                                                <i className="fab fa-discord"></i>
                                                <span>Tao Community</span>
                                            </a>
                                        </li>

                                         {/* <li className="youtube">
                                            <Link to="#" className="link-underline">
                                                <i className="fab fa-youtube"></i>
                                                <span>Youtube</span>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                                {/* <div className="footer-list">
                                    <h6>Menu</h6>
                                    <ul>
                                        <li>
                                            <Link to="about" className="link-underline">
                                                <span>About</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="about" className="link-underline">
                                                <span>Our Team</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="features-1" className="link-underline">
                                                <span>Features</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="blog-1" className="link-underline">
                                                <span>Blog</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="about" className="link-underline">
                                                <span>How It Works</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="contact" className="link-underline">
                                                <span>Contact</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="footer-list">
                                    <h6>Explore</h6>
                                    <ul>
                                        <li>
                                            <a href="https://www.bittensor.com" className="link-underline" target='_blank' rel='noreferrer'>
                                                <span>Bittensor</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://taostats.io/" className="link-underline" target='_blank' rel='noreferrer'>
                                                <span>Taostats</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://tao.news/" className="link-underline" target='_blank' rel='noreferrer'>
                                                <span>TAO.news</span>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <Link to="contact" className="link-underline">
                                                <span>Help Center</span>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-pattern" style={{backgroundImage: `url(${background_image_1})`}}></div>
            </div>
        </footer>
    );
};

export default Footer;
