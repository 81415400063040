const features = [
    {
        icon: 'lab la-discord',
        heading: 'Discord',
        excerpt: 'Connect with Hal on Discord and enjoy seamless AI-powered conversations with your friends and communities.',
        to: '/platforms/discord'
    },
    {
        icon: 'lab la-twitter',
        heading: 'Twitter',
        excerpt: 'Follow Hal on Twitter, engage in AI-assisted conversations and witness the fusion of AI and social media in action.',
        to: '/platforms/twitter'
    },
    {
        icon: 'las la-kiwi-bird',
        heading: 'Nostr',
        excerpt: 'Join Hal on Nostr where truly decentralized and censorship-resistant social media and uncensorable AI meet.',
        to: '/platforms/nostr'
    },
    {
        icon: 'lab la-whatsapp',
        heading: 'Whatsapp',
        excerpt: 'Add Hal to your WhatsApp contacts and chat with your personal AI assistant anytime, anyplace, anywhere.',
        to: '/platforms/whatsapp'
    },
    {
        icon: 'lab la-telegram',
        heading: 'Telegram',
        excerpt: 'Experience the magic of uncensorable AI with Hal on Telegram - the perfect platform for private and secure messaging.',
        to: '/platforms/telegram'
    },
    {
        icon: 'lab la-mastodon',
        heading: 'Mastodon',
        excerpt: 'Join Hal on Mastodon and experience decentralized social media and unparalleled AI-powered conversations at its best.',
        to: '/platforms/mastodon'
    }
];

export default features;