import React from 'react';

// react router dom
import { Routes, Route } from  'react-router-dom';

// pages
import Header from './layout/Header';
import Index from './pages/Index';


import PlatformDetail from './pages/PlatformDetail';
// import PrivacyPolicy from './pages/PrivacyPolicy';
import Footer from './layout/Footer';
import CustomScrollbar from './components/CustomScrollbar';
import Preloader from './components/Preloader';

const Markup = () => {

    return (
        <>
            <Preloader/>
            <CustomScrollbar/>
            <div className="main-wrapper">
                {/* header - start */}
                {/* <Header type={ useLocation().pathname !== '/' ? 'navigation-1' : '' } /> */}
                <Header type=""/>
                {/* header - end */}
                <Routes>
                    <Route path="/" element={<Index/>} />
                    {/* <Route path="/about" element={<About/>} />
                    <Route path="/blog-1" element={<Blog1/>} />
                    <Route path="/blog-2" element={<Blog2/>} />
                    <Route path="/blog-detail-1" element={<BlogDetail1/>} />
                    <Route path="/blog-detail-2" element={<BlogDetail2/>} />
                    <Route path="/contact" element={<Contact/>} /> */}
                    <Route path="/platforms/:platform" element={<PlatformDetail/>} />
                    {/* <Route path="/feature-detail-2" element={<FeatureDetail2/>} /> */}
                    {/* <Route path="/platforms" element={<Platforms/>} /> */}
                    {/* <Route path="/features-2" element={<Features2/>} /> */}
                    {/* <Route path="/pricing" element={<Pricing/>} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>} /> */}
                </Routes>
                {/* footer - start */}
                <Footer/>
                {/* footer - end */}
            </div>
        </>
    );
};

export default Markup;
