import BackgroundAnimation from '../components/BackgroundAnimation';
import TabSection from '../components/TabSection';
import coming_soon from '../../assets/images/coming-soon.png';

const getData = (platform) => ({
  icon: "las la-hourglass-half",
  title: "Coming soon",
  content: (
      <>
          <p className="paragraph-big">{platform} integration is currently under development and will be available soon. Stay tuned for updates on when you can connect with Hal on your favorite platform.</p>
          <div className="pattern-image">
              <div className="pattern-image-wrapper">
                  {/* <i className="las la-hourglass-half"></i> */}
                  <img className="drop-shadow-1" src={coming_soon} height="470" alt="pattern" />
                  <div className="background-pattern background-pattern-radius drop-shadow-1">
                      <BackgroundAnimation/>
                      <div className="background-pattern-gradient"></div>
                  </div>
              </div>
          </div>
      </>
  )
});

const ComingSoon = ({ platform }) => {
    return <TabSection data={[getData(platform)]} subHeading='How it Works' />
};

export default ComingSoon;
