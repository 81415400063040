const faq = [
    {
        question: 'How does Hal work?',
        answer: "With Hal, it's as simple as asking your question on your preferred platform. Whether it's Discord, Twitter, Nostr, WhatsApp, Telegram, or any other supported platform, Hal will utilize advanced AI technology to generate a response tailored to your query. Just engage with Hal on your desired platform, ask your question, and experience AI-generated answers in real-time."
    },
    {
        question: 'Is Hal available and live right now?',
        answer: 'We are currently in the process of rolling out Hal across different platforms. To check if Hal is available for your specific platform, please refer to the detailed pages dedicated to each platform on our website.'
    },
    {
        question: "What does it mean by 'uncensorable AI'",
        answer: "When we refer to 'uncensorable AI,' we mean that the AI-powered interactions facilitated by Bittensor, such as those with Hal, are decentralized in nature. Unlike traditional centralized systems, decentralized AI cannot be easily controlled, manipulated, or restricted by any single authority or entity. This decentralization ensures that AI-based conversations and content remain resistant to censorship, allowing for open and free expression of ideas without fear of arbitrary limitations or restrictions."
    },
    {
        question: 'What sets Hal apart from ChatGPT?',
        answer: "While Hal and ChatGPT provide similar conversational experiences, there are some key differences. Hal is powered by Bittensor, a decentralized AI framework, which brings the advantages of decentralization, censorship resistance, and enhanced privacy to the table. Unlike ChatGPT, which relies on centralized infrastructure, Hal operates in a distributed network, making it more resilient and less susceptible to single points of failure or control. Additionally, by leveraging Bittensor's technology, Hal enables direct access to its AI capabilities from popular social networks and messenger apps, offering a seamless and familiar user experience. Ultimately, Hal's integration with Bittensor sets it apart by combining the power of conversational AI with the benefits of decentralization."
    }
];

export default faq;